// // inspired by https://github.com/paulirish/lite-youtube-embed/blob/be6f03ef53b33866e38a917a256cefca95262804/src/lite-yt-embed.js
import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './youtube.scss';
import { attachScript } from '@wix/wix-vod-shared/common';

const buildUrl = ({
  src,
  mobileMode,
  showControls,
  locale,
  shouldAutoplay,
}: {
  src: string;
  mobileMode: boolean;
  showControls: boolean;
  locale: string;
  shouldAutoplay: boolean;
}) => {
  const { origin } = window.location;
  // we need second parameter, because sometimes the url starts with //, and we need to pass a default protocol
  const url = new URL(src, window.location.href);
  // https is required for autoplay
  url.protocol = 'https';
  url.searchParams.set('enablejsapi', '1');
  url.searchParams.set('hl', locale);
  url.searchParams.set('rel', '0');
  url.searchParams.set('origin', origin);
  if (shouldAutoplay) {
    url.searchParams.set('autoplay', '1');
  }
  url.host = 'www.youtube-nocookie.com';

  if (mobileMode) {
    url.searchParams.set('playsinline', '1');
  }

  if (!showControls) {
    url.searchParams.set('controls', '0');
  }

  return url.href;
};

const addPreconnectPrefetch = (url: string) => {
  const linkElement = document.createElement('link');
  linkElement.rel = 'preconnect';
  linkElement.href = url;
  document.head.appendChild(linkElement);
};

let isWarmedUp = false;
export const warmYoutubeConnections = () => {
  if (isWarmedUp) {
    return;
  }
  // took these from the mentioned above Paul Irish's lib
  const warmupUrls = [
    'https://www.youtube-nocookie.com',
    'https://www.google.com',
    'https://googleads.g.doubleclick.net',
    'https://static.doubleclick.net',
  ];
  for (const url of warmupUrls) {
    addPreconnectPrefetch(url);
  }
  isWarmedUp = true;
};

type Props = {
  src: string;
  locale?: string;

  onPlay: () => void;
  onPause: () => void;
  onEnd: () => void;
  onIframeRef: (iframe: HTMLIFrameElement) => void;

  width?: number;
  height?: number;
  className?: string;

  mobileMode?: boolean;

  isVideoPlayRequested?: boolean;
  isVideoPauseRequested?: boolean;
  isVideoPlaying?: boolean;
  isVideoPaused?: boolean;
  fillAllSpace?: boolean;

  loop?: boolean;
  muted?: boolean;
  showControls?: boolean;
};

const scriptApiUrl = 'https://www.youtube.com/iframe_api';
let apiPromise: Promise<void> | null = null;

const loadSDK = () => {
  if (apiPromise) {
    return apiPromise;
  }
  apiPromise = Promise.race([
    new Promise<void>((resolve) => {
      // @ts-expect-error
      window.onYouTubeIframeAPIReady = resolve;
    }),
    new Promise<void>((resolve) => {
      if (window.YT) {
        // somebody else already loaded api
        // @ts-expect-error
        window.YT.ready(resolve);
      }
    }),
  ]);
  attachScript(scriptApiUrl);
  return apiPromise;
};

const initYtPlayer = (iframeEl: HTMLIFrameElement, events: YT.Events) => {
  new window.YT.Player(iframeEl, {
    events,
  });
};

type YTPlayer = YT.Player & {
  getVideoData: () => { video_id: string };
};

type YTPlayerRef = React.MutableRefObject<YTPlayer | null>;

const usePauseRequested = ({
  playerRef,
  isVideoPauseRequested,
  isReady,
}: {
  playerRef: YTPlayerRef;
  isVideoPauseRequested: boolean;
  isReady: boolean;
}) => {
  useEffect(() => {
    const player = playerRef.current;
    if (!player || !isReady) {
      return;
    }
    if (isVideoPauseRequested) {
      player.pauseVideo();
    }
  }, [isVideoPauseRequested, playerRef, isReady]);
};

const useMuted = ({
  playerRef,
  muted,
  isReady,
}: {
  playerRef: YTPlayerRef;
  muted: boolean;
  isReady: boolean;
}) => {
  useEffect(() => {
    const player = playerRef.current;
    if (!player || !isReady) {
      return;
    }
    if (muted) {
      player.mute();
    } else {
      player.unMute();
    }
  }, [muted, playerRef, isReady]);
};

const useLoop = ({
  playerRef,
  loop,
  isReady,
}: {
  playerRef: YTPlayerRef;
  loop: boolean;
  isReady: boolean;
}) => {
  useEffect(() => {
    const player = playerRef.current;
    if (!player || !isReady) {
      return;
    }
    if (loop) {
      const { video_id: videoId } = player.getVideoData();
      // need load playlist to set loop to true
      // but loadPlay list sets video to the beginning
      player.loadPlaylist(videoId);
      player.setLoop(true);
    } else {
      player.setLoop(false);
    }
  }, [loop, playerRef, isReady]);
};

const usePlayRequested = ({
  isVideoPlayRequested,
  src,
  onPlay,
  onPause,
  onEnd,
  onIframeRef,
  locale,
  mobileMode,
  showControls,
}: {
  isVideoPlayRequested: boolean;
  src: string;
  onPlay: () => void;
  onPause: () => void;
  onEnd: () => void;
  onIframeRef: (iframe: HTMLIFrameElement) => void;
  locale: string;
  mobileMode: boolean;
  showControls: boolean;
}) => {
  const playerRef = useRef<YTPlayer | null>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isReady, setIsReady] = useState(false);
  const iframeInitedRef = useRef(false);
  const initIframe = useCallback(() => {
    if (iframeInitedRef.current) {
      return;
    }
    const iframe = iframeRef.current;
    if (!iframe) {
      return;
    }
    iframe.onload = async () => {
      await loadSDK();
      initYtPlayer(iframe, {
        onReady: ({ target }) => {
          playerRef.current = target as YTPlayer;
          if (target.getPlayerState() === YT.PlayerState.PLAYING) {
            onPlay();
          }
          setIsReady(true);
        },
        onStateChange: ({ data }) => {
          switch (data) {
            case YT.PlayerState.PLAYING: {
              onPlay();
              break;
            }
            case YT.PlayerState.PAUSED: {
              onPause();
              break;
            }
            case YT.PlayerState.ENDED: {
              onEnd();
              break;
            }
            default:
              break;
          }
        },
      });
    };
    iframe.setAttribute(
      'src',
      buildUrl({
        src,
        mobileMode,
        showControls,
        locale,
        shouldAutoplay: isVideoPlayRequested,
      }),
    );
    onIframeRef(iframe);
    iframeInitedRef.current = true;
  }, [
    src,
    mobileMode,
    showControls,
    locale,
    isVideoPlayRequested,
    onEnd,
    onIframeRef,
    onPause,
    onPlay,
  ]);
  useEffect(() => {
    if (!isVideoPlayRequested) {
      return;
    }
    if (!iframeInitedRef.current) {
      initIframe();
      return;
    }
    const player = playerRef.current;
    if (!player || !isReady) {
      return;
    }
    player.playVideo();
  }, [isVideoPlayRequested, playerRef, isReady, initIframe]);
  return { isReady, playerRef, iframeRef };
};

export const YoutubeExternal: React.FC<Props> = ({
  src,
  mobileMode = false,
  showControls = false,
  fillAllSpace = false,
  width,
  height,
  isVideoPlayRequested = false,
  isVideoPlaying = false,
  isVideoPauseRequested = false,
  isVideoPaused = false,
  onPause,
  onPlay,
  onEnd,
  onIframeRef,
  locale = 'en',
  muted = false,
  loop = false,
}) => {
  const tabIndex =
    isVideoPauseRequested ||
    isVideoPlayRequested ||
    isVideoPlaying ||
    isVideoPaused
      ? 0
      : -1;

  const { isReady, playerRef, iframeRef } = usePlayRequested({
    isVideoPlayRequested,
    src,
    mobileMode,
    showControls,
    locale,
    onEnd,
    onIframeRef,
    onPause,
    onPlay,
  });

  usePauseRequested({
    playerRef,
    isVideoPauseRequested,
    isReady,
  });

  useMuted({ playerRef, muted, isReady });
  useLoop({ playerRef, loop, isReady });

  return (
    <iframe
      className={classNames(styles.iframe, {
        [styles.fillAllSpace]: fillAllSpace,
      })}
      width={width}
      height={height}
      tabIndex={tabIndex}
      ref={iframeRef}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
};
